import React from 'react';

const OnlyfunBanner = () => {
	return (
		<div className='bg-transparent relative'>
			<img
				className='absolute z-20 top-0 left-0 h-full w-full'
				src='banner-bg.png'
				alt='background'
			/>
			<div className='opacity px-4 py-4 bg-gradient-to-r w-full from-[#123F5F]/[0.9] via-[#143044]/[0.9] to-[#6C0C7F]/[0.5] flex items-center sm:flex-row flex-col justify-center gap-x-2 font-urbanist font-semibold text-base lg:text-xl relative z-30'>
				<span className='flex items-center gap-x-1 flex-wrap text-center'>
					Blobscriptions Whitepaper v2 dropping soon!
				</span>
			</div>
		</div>
	);
};

export default OnlyfunBanner;
