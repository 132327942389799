import { Navbar, NavbarMenu, NavbarMenuToggle } from '@nextui-org/navbar';
import React from 'react';

const NavHeader = () => {
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	return (
		<Navbar
			onMenuOpenChange={setIsMenuOpen}
			className='navheader bg-transparent px-6 sm:px-8 md:px-12 py-4 w-full [&>header]:max-w-full'
		>
			<NavbarMenuToggle
				aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
				className='lg:hidden'
			/>

			<NavbarMenu className='py-12 h-[250px]'>
				<div className='flex items-center flex-col gap-y-4'>
					<a
						className='text-white font-monument'
						target='_blank'
						rel='noreferrer'
						href='Blobscriptions-Whitepaper.pdf'
					>
						Whitepaper
					</a>
					<div className='flex items-center gap-x-4'>
						<a
							href='https://x.com/blobscriptions'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='twitter.png'
								alt='twitter'
								className='h-[60px] w-[60px]'
							/>
						</a>
						{/* <a
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='discord.png'
								alt='discord'
								className='h-[60px] w-[60px]'
							/>
						</a> */}
						<a
							href='https://t.me/blobscriptions'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='telegram.png'
								alt='telegram'
								className='h-[60px] w-[60px]'
							/>
						</a>
					</div>
					<a
						href='http://app.blobscriptions.xyz/'
						target='_blank'
						style={{
							background: 'linear-gradient(90deg, rgba(123, 32, 226, 0.30) -15.29%, rgba(123, 32, 226, 0.03) 92.85%)'
						}}
						className='flex items-center justify-center sm:hidden border-[2px] border-primary border-solid md:rounded-[18px] rounded-[16px] md:h-[60px] sm:h-[50px] h-[40px] md:px-[22px] sm:px-[18px] px-[16px] text-primary md:text-[18px] sm:text-[16px] text-[14px] font-monument'
						rel='noreferrer'
					>
						Launch App
					</a>
				</div>
			</NavbarMenu>

			<div className='flex items-center w-full justify-between'>
				<img
					src='blobscriptions-logo.png'
					alt='blobscriptions-logo'
					className='lg:w-[240px] lg:h-[57px] md:w-[200px] md:h-[50px] sm:w-[180px] sm:h-[40px] w-[160px] h-[35px]'
				/>
				<div className='items-center gap-x-4 flex'>
					<div className='items-center gap-x-4 hidden lg:flex'>
						<a
							className='text-white font-monument'
							target='_blank'
							rel='noreferrer'
							href='Blobscriptions-Whitepaper.pdf'
						>
							Whitepaper
						</a>
						<div className='flex items-center gap-x-4'>
							<a
								href='https://x.com/blobscriptions'
								target='_blank'
								rel='noreferrer'
							>
								<img
									src='twitter.png'
									alt='twitter'
									className='h-[60px] w-[60px]'
								/>
							</a>
							{/* <a
								target='_blank'
								rel='noreferrer'
							>
								<img
									src='discord.png'
									alt='discord'
									className='h-[60px] w-[60px]'
								/>
							</a> */}
							<a
								href='https://t.me/blobscriptions'
								target='_blank'
								rel='noreferrer'
							>
								<img
									src='telegram.png'
									alt='telegram'
									className='h-[60px] w-[60px]'
								/>
							</a>
						</div>
					</div>
					<a
						href='http://app.blobscriptions.xyz/'
						target='_blank'
						style={{
							background: 'linear-gradient(90deg, rgba(123, 32, 226, 0.30) -15.29%, rgba(123, 32, 226, 0.03) 92.85%)'
						}}
						className='hidden sm:flex items-center justify-center border-[2px] border-primary border-solid md:rounded-[18px] rounded-[16px] md:h-[60px] sm:h-[50px] h-[40px] md:px-[22px] sm:px-[18px] px-[16px] text-primary md:text-[18px] sm:text-[16px] text-[14px] font-monument'
						rel='noreferrer'
					>
						Launch App
					</a>
				</div>
			</div>
		</Navbar>
	);
};

export default NavHeader;
