import React from 'react';

const Partners = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='text-center text-[28px] mb-6 text-grey-1'>Partners</p>
			<div className='flex justify-center items-center lg:flex-row flex-col gap-16 flex-wrap'>
				<div className='flex items-center gap-16 sm:flex-row flex-col'>
					<img
						src='avail-logo.png'
						className='sm:w-[150px] sm:h-[45px] w-[120px] h-[30px]'
					/>
					<img
						src='blast-logo.png'
						className='sm:w-[190px] sm:h-[40px] w-[160px] h-[25px]'
					/>
				</div>
				<div className='flex items-center gap-16 sm:flex-row flex-col'>
					<img
						src='biconomy-logo.png'
						className='sm:w-[180px] sm:h-[50px] w-[150px] h-[35px]'
					/>
					<img
						src='polkadot-logo.png'
						className='sm:w-[210px] sm:h-[45px] w-[180px] h-[30px]'
					/>
				</div>
				<div className='flex items-center gap-16 sm:flex-row flex-col'>
					<img
						src='townhall-logo.png'
						className='sm:w-[200px] sm:h-[30px] w-[190px] h-[25px]'
					/>
					<div className='w-[200px]'>
						<img
							src='eclipse-logo-new.png'
							alt='eclipse'
						/>
					</div>
				</div>
				<div className='w-[200px]'>
					<img
						src='berachain-logo.png'
						alt='eclipse'
					/>
				</div>
			</div>
			<p className='text-center text-[28px] mb-6 text-grey-1 mt-12'>Acquisition</p>
			<div className='flex justify-center items-center lg:flex-row flex-col gap-16'>
				<div className='flex items-center gap-16 sm:flex-row flex-col'>
					<img
						src='onlyfun-logo.png'
						className='sm:w-[200px] sm:h-[40px] w-[150px] h-[30px]'
					/>
				</div>
			</div>
		</div>
	);
};

export default Partners;
